const Chains = {
    'BEP20': {
        'name': 'Smart Chain',
        'chainId': '0x38',
        'uri': 'https://bsc-dataseed.binance.org/',
        'bexp': 'https://bscscan.com',
        'symbol': 'BNB',
        'decimals': 18,
    },
    /*
        Testnet
        'name': 'Smart Chain - Testnet',
        'chainId': '0x61',
        'uri': 'https://data-seed-prebsc-1-s1.binance.org:8545',
        'bexp': 'https://testnet.bscscan.com',
        'symbol': 'BNB',
        'decimals': 18,

        Mainnet
          'name': 'Smart Chain',
        'chainId': '0x38',
        'uri': 'https://bsc-dataseed.binance.org/', //
        'bexp': 'https://bscscan.com',
        'symbol': 'BNB',
        'decimals': 18,
    */

}
export default Chains;