import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './button.scss';


Button.propTypes = {
    title: PropTypes.string,
    path: PropTypes.string,
    className: PropTypes.string,
    target: PropTypes.string,
};

function Button(props) {
    const { title, path, onClick, className, target } = props;
    return (
        <Link className={`tf-button btn-effect ${className}`} to={path} onClick={onClick} target={target}>
            <span className="boder-fade"></span>
            <span className="effect" style={{
                justifyContent: 'center',
                textAlign:'center'
            }}>{title}</span>
        </Link>
    );
}

export default Button;