import { React, useState } from "react";
import './style.scss';
import logo1 from '../../assets/images/post/53f3577a.png';
import logo2 from '../../assets/images/post/bb392754.png';
import logo3 from '../../assets/images/post/1ae7a337.png';
import Button from "../../Components/button";

const SectionItems = () => {
    const data = [
        {
            id: 1,
            img: logo1,
            title: 'Armageddon AG147',
            desc: 'Grenade Launcher Machine Gun',
        },
        {
            id: 2,
            img: logo2,
            title: 'Anti-Matter YU6',
            desc: 'No living thing can stand against it.',
        },
        {
            id: 3,
            img: logo3,
            title: 'Automizer Z529',
            desc: 'Lighter, faster, more effective',
        }
    ];

    return (
        <section className="tf-section how-we-work">
            <div className="container">
                <div className="row pl-70">
                    {
                        data.map(item => (
                            <div key={item.id} className="col-md-4">
                                <div className={`box-text st2 corner-box`}>
                                    <div className="nft-item__box">
                                        <div className="nft-item__thumb">
                                            <img src={item.img} alt="img" />
                                        </div>
                                        <div className="nft-item__content">
                                            <h4 className="title">{item.title}</h4>
                                            <p className="name">{item.desc} <a></a></p>



                                            <div className="nft-item__bid">
                                                <div className="nft-item__price">
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}
export default SectionItems;