import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import Button from "../Components/button";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
    const [username, setUsername] = useState('');
    const [referral, setReferral] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [captcha, setCaptch] = useState("");
    const capkey = "6LfAogcmAAAAAM466js4fCL2HrajauuigOJwlZJh";
    const captchaRef=useRef()

    useEffect(() => {
        // localStorage'da kaydedilen token kontrol ediliyor
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
        setToken(storedToken);
        }
    }, []);
    
    const handleSubmit = async (e) => {

        try {
        setCaptch(await captchaRef.current.getValue())
        // API'ye istek gönderme
        const response = await fetch( process.env.REACT_APP_API + '/user/register', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, email, password, referral, captcha })
        });
    
        // İstek başarılıysa
        const data = await response.json();

        if (response.ok && data.status == "success") {
            const receivedToken = "Bearer " + data.token;
            const receivedUser = data.data;
    
            
            // Bearer token'ı saklama
            localStorage.setItem('token', receivedToken);
            setToken(receivedToken);
            localStorage.setItem('success', 'Account Created Successfully');

            window.location.replace('/login')
            // İşlem yapmak veya oturum açmayı işaretlemek için burada kullanıcı verilerini kullanabilirsiniz
            //console.log('Kullanıcı kayıt başarılı:', data);
        } else {
            // İstek başarısızsa hata mesajını alabilirsiniz
            //const error = await response.json();
            document.getElementById('resp-result').innerHTML = data.message;
            console.log('kayıt hatası:', data.message);
        }
        } catch (error) {
        // Hata durumunda
        console.log('Bir hata oluştu:', error);
        }
    };
    
    const handleLogout = () => {
        // Token'ı sıfırlama ve localStorage'dan kaldırma
        setToken('');
        localStorage.removeItem('token');
    };
    
    if (token) {
        // Oturum açıldıysa gösterilecek içerik
        //return window.location.replace('/');
    }
    return (
        <section className="contact-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="contact__content">
                            <h2 className="overlay-title"><span>register</span></h2>
                            <h2 className="title">REGISTER</h2>
                            <p id="desc">Do you already have an account?
                                <br />
                                <a href="/login" id="brand"> Login now!</a></p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-10">
                        <div className="contact__form-wrap">
                            <form onSubmit={ handleSubmit }>
                                <div className="input-grp">
                                    <input name="username" type="text" placeholder="Username *" required=""
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)} />
                                </div>
                                <div className="input-grp">
                                    <input name="email" type="email" placeholder="Email *" required="" 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="input-grp">
                                    <input name="password" type="password" placeholder="Password *" requred="" 
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div className="input-grp">
                                    <input name="referral" type="text" placeholder="Referral" 
                                    value={referral}
                                    onChange={(e) => setReferral(e.target.value)} />
                                </div>

                                <ReCAPTCHA
                                    ref={captchaRef}
                                    sitekey={capkey}
                                    size="normal"
                                    hl="en"
                                    theme="dark"
                                    onErrored={(e)=>{console.log('e',e)}}
                                    onExpired={(ex)=>{console.log('ex',ex)}}
                                    onChange={() => {
                                        setCaptch(captchaRef.current.getValue());
                                    }}

                                />
                                <br>
                                </br>
                                <Button onClick={handleSubmit} title="Register" />

                            </form>
                            <p className="ajax-response" id='resp-result' style={{ color: "#ff5656fc"}}></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Register;