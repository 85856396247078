import React from "react";
const networkList = [
    {
        'title': 'USDT (BEP20)',
        'key': 'usdt-bep20'
    },
    {
        'title': 'BUSD (BEP20)',
        'key': 'busd-bep20'
    },
    {
        'title': 'BNB (BEP20)',
        'key': 'bnb-bep20'
    },
    

];
export default networkList;