import React, { useState, useEffect } from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom'
import maf_game_sample from '../assets/images/background/maf_game_sample.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import swal from 'sweetalert';

function Footer(props) {

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const handleSubscribe = async (e) => {
        e.preventDefault();
        let email = document.getElementById('subscribe-email').value;
        let subs_result = document.getElementById('subs-result');
        if(email == "" ||  email == " "){
            subs_result.classList.add('text-danger');
            subs_result.innerHTML = "Please enter your email";
        }else{
            subs_result.classList.remove('text-danger');

            const response = await fetch(process.env.REACT_APP_API + '/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            let result = await response.json();
            if( result.status == 'success' ){
                subs_result.classList.add('text-success');
                subs_result.innerHTML = result.message;
                swal('Successful', '', 'success');
            }else{
                subs_result.classList.add('text-danger');
                subs_result.innerHTML = result.message;
            }
        }
    }

    return (
        <footer id="footer">
            <div className="footer-main">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-4'>
                            <img src={maf_game_sample} alt='maf-game-sample' style={{
                                borderRadius:"16px",
                            }} />
                        </div>
                        <div className='col-md-6'>
                           
                            <h5 className="heading">Newsletter Subscription</h5>
                            <p>Subscribe to our newsletter to be informed about innovations.</p>
                            <form onSubmit={handleSubscribe} action="#" id="subscribe-form">
                                <input type="email" placeholder="Enter your email address" required="" id="subscribe-email" />
                                <button className="tf-button-st2 btn-effect" type="submit" id="subscribe-button"> <span className="effect">Subscribe</span></button>
                            </form>
                        </div>
                        <b className='text' id='subs-result'></b>
                    </div>
                </div>

            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="wrap-fx">
                        <div className="Copyright">
                            <p>MAF 2023- ALL rights reserved</p>
                        </div>
                        
                    </div>
                </div>

            </div>

            {
                isVisible &&
                <Link onClick={scrollToTop} to='#' id="scroll-top"></Link>
            }
        </footer>
    );
}

export default Footer;