import React from "react";
const UnderConstructor = () => {
    return (

        <div className='about'>
            <section className="breadcrumb-area">
                <div className="container">
                    <div className="breadcrumb__wrapper">
                        <div className="row">
                            <div className="col-xl-6 col-lg-7">
                                <div className="breadcrumb__content">
                                    <h2 className="title">Coming Soon</h2>
                                    <nav aria-label="breadcrumb">

                                    </nav>
                                    <br />
                                    
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-5 position-relative d-none d-lg-block">
                                <div className="breadcrumb__img">
                                    <h5>This page will be published very soon...</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default UnderConstructor;