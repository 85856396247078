import React from "react";
import SectionVideo from "./SectionVideo";
import SectionItems from "./SectionItems";
import SectionAbout from "./SectionAbout";
import SectionJoin from "./SectionJoin";
import SectionRoadmap from "./SectionRoadmap";

const HomePage = () => {
    return (
        <div className='home-1'>
            <SectionVideo />
            <SectionItems />
            <SectionAbout />
            <SectionJoin />
            <SectionRoadmap />
        </div>

    );
}
export default HomePage;