import React, { useState, useEffect } from 'react';
import './style.scss';
import Button from '../../Components/button';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import aboutVideo from '../../assets/video/maf_section_3.mp4';

const SectionAbout = () => {

    const [dataBlock] = useState(
        {
            subtitle: 'About us',
            title: 'WHAT IS THE METAVERSE ARMY FORCES?',
            desc: ''
        }
    )
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch( process.env.REACT_APP_API + '/aboutus'); 
            const jsonData = await response.json();

            if(jsonData.status == "success"){
                setData(jsonData.data);
            }
        } catch (error) {
        console.log('Veri alınamadı:', error);
        }
    }

    return (
        <section className="tf-section tf-about">
            <div className="container">
                <div className="content-about mobie-40" data-aos="fade-up" data-aos-duration="800">
                    <div className="tf-title st2">
                        <p className="h8 sub-title">{dataBlock.subtitle}</p>
                        {/* <h4 className="title">{dataBlock.title}</h4>*/}
                    </div>

                    <div className='row'>
                        <div className='col-md-6'>
                            <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
                        </div>
                        <div className='col-md-6'>
                            <video playsInline autoPlay muted loop className='about_video' >
                                <source src={aboutVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}
export default SectionAbout;