import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RoadMapItem from './RoadMapItem.jsx';
import './index.scss';



const SectionRoadmap = () => {

    const [dataBlock] = useState(
        {
            subtitle: 'ROADMAP',
            title: 'MAF Timeline'
        }
    )


    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch( process.env.REACT_APP_API + '/roadmap'); 
            const jsonData = await response.json();

            if(jsonData.status == "success"){
                setData(jsonData.data);
            }
        } catch (error) {
        console.log('Veri alınamadı:', error);
        }
    }


    return (

        <section className="tf-section tf-roadmap" style={{padding: '0'}}>
        <div className="overlay"></div>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="tf-title mb-30" data-aos="fade-up" data-aos-duration="800">
                        <p className="h8 sub-title">{dataBlock.subtitle}</p>
                        <h4 className="title">{dataBlock.title}</h4>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="roadmap scrol">

                            {
                                data.map(item => (
                                    <RoadMapItem key={item.id} item ={item} />
                                ))
                            }
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}
export default SectionRoadmap;