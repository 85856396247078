import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bepPresaleContract : null,
  bepMAFToken: null,
  bepBUSDToken: null,
  bepUSDTToken: null,
  ercUSDTToken: null,
};

export const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {
    setBepPresaleContract : (state, action) => {
      state.bepPresaleContract = action.payload;
    },
    setBepMAFToken: (state, action) => {
      state.bepMAFToken = action.payload;
    },
    setBepBUSDToken: (state, action) => {
      state.bepBUSDToken = action.payload;
    },
    setBepUSDTToken: (state, action) => {
      state.bepUSDTToken = action.payload;
    },
    setErcUSDTToken: (state, action) => {
      state.ercUSDTToken = action.payload;
    },
  },
});

export const { setBepPresaleContract, setBepBUSDToken, setBepMAFToken, setBepUSDTToken, setErcUSDTToken } = contractsSlice.actions;

export default contractsSlice.reducer;