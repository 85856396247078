import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import Button from "../Components/button";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [authUser, setAuth] = useState('');
    const [captcha, setCaptch] = useState("");
    const capkey = "6LfAogcmAAAAAM466js4fCL2HrajauuigOJwlZJh";
    const captchaRef = useRef();

    useEffect(() => {
      // localStorage'da kaydedilen token kontrol ediliyor
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        setToken(storedToken);
      }
    }, []);
  

    var msgBox = document.getElementById('resp-result');

    if( msgBox != null && localStorage.getItem('success') != null){
      msgBox.innerHTML = localStorage.getItem('success');
      msgBox.style.color = "rgb(86 255 119 / 99%)";
    }

    const handleSubmit = async (e) => {
      try {
        setCaptch(await captchaRef.current.getValue())

        // success bildiriminin silinmesi
        localStorage.removeItem('success');

        // API'ye istek gönderme
        const response = await fetch( process.env.REACT_APP_API + '/user/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email, password, captcha })
        });
  
        // İstek başarılıysa
        const data = await response.json();

        if (response.ok && data.status == "success") {
          const receivedToken = "Bearer " + data.token;
          const receivedUser = data.data;
  
          
          // Bearer token'ı saklama
          localStorage.setItem('authUser', receivedUser);
          localStorage.setItem('token', receivedToken);
          setToken(receivedToken);
          setAuth(receivedUser);
  
          // İşlem yapmak veya oturum açmayı işaretlemek için burada kullanıcı verilerini kullanabilirsiniz
          //console.log('Kullanıcı girişi başarılı:', data);
        } else {
          // İstek başarısızsa hata mesajını alabilirsiniz
          //const error = await response.json();
          document.getElementById('resp-result').innerHTML = data.message;
          document.getElementById('resp-result').style.color = "#ff5656fc";
          
          console.log('Giriş hatası:', data);
        }
      } catch (error) {
        // Hata durumunda
        console.log('Bir hata oluştu:', error);
      }
    };
  
    const handleLogout = () => {
      // Token'ı sıfırlama ve localStorage'dan kaldırma
      setToken('');
      localStorage.removeItem('token');
    };
  
    if (token && authUser) {
      // Oturum açıldıysa gösterilecek içerik
      return window.location.replace('/dashboard');
    }

    return (
        <div>
            <section className="contact-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="contact__content">
                                <h2 className="overlay-title"><span>login</span></h2>
                                <h2 className="title">LOGIN YOUR ACCOUNT</h2>
                                <p id="desc">Don't you have an account?
                                <br />
                                <a href="/register" id="brand"> Register now!</a></p>
                               
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                            <div className="contact__form-wrap">
                                
                                    <input type="hidden" name="_token" value="f6pIb84bVEP1Q4AY93Ck6EMw8aP9lQN5WbViUsnU" />
                                    <div className="input-grp">
                                        <input name="email" type="email" placeholder="Email *" required="" 
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="input-grp">
                                        <input name="password" type="password" placeholder="Password *" required="" value={password}
                                        onChange={(e) => setPassword(e.target.value)}/>
                                    </div>

                                    <ReCAPTCHA
                                        ref={captchaRef}
                                        sitekey={capkey}
                                        size="normal"
                                        hl="en"
                                        theme="dark"
                                        onChange={() => {
                                            setCaptch(captchaRef.current.getValue());
                                        }}
                                    />
                                   <br>
                                    </br>
                                    <Button onClick={handleSubmit} title="Login Now" />
                                
                                <p className="ajax-response" id='resp-result' style={{ color: "#ff5656fc"}}>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Login;
