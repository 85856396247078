import React from 'react';
import PropTypes from 'prop-types';

RoadMapItem.propTypes = {
    item: PropTypes.object,
};

function RoadMapItem(props) {
    const {item} = props;
    return (
        <div key={item.id} className={`rm-box ${item.positon} aos-init`} data-aos="zoom-in" data-aos-duration="1200">
            <div className={`corner-box ${item.style}`}>
                <strong style={{color :'white'}}>{item.time}</strong>
                <ul>
                    {
                        item.list.map((li,idx) => (
                            <li key={li.id}>{li.title}</li>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
}

export default RoadMapItem;